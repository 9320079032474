import React, { memo } from 'react';
import { Row, Col } from 'antd';
// import BtnsBlock from './BtnsBlock';
import DataBlock from './DataBlock';
import BtnsBlock from './BtnsBlock';
import {useSelector} from "react-redux";
import QRPaymentModal from "../../Modals/QRPaymentModal/QRPaymentModal";

const ContributionCard = ({ record, colNum }) => {
  const config = useSelector(state => state.config);
  const settings = config['1c_configs'].return.НастройкиВзносов;

  return (
    <div className='more-info-wrapper'>
      {colNum === 24 ? (
        // mobile
        <>
          {/* <Row>
            <Col span={colNum}>
              <BtnsBlock setVisible={setVisible} uid={record.UIDДоговора} />
            </Col>
          </Row> */}

          <Row>
            <Col span={colNum}>
              <DataBlock record={record} />
            </Col>
          </Row>
        </>
      ) : (
        // desctop
        <>
          <Row>
            <Col span={colNum}>
              <DataBlock record={record} />
            </Col>

            <Col span={colNum}>
              <BtnsBlock key={record.Взнос.UIDВзноса} settings={settings} record={record} />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default memo(ContributionCard);
